.classCheckDetail .btn-box {
  text-align: center;
  margin-top: 30px;
}
.classCheckDetail .el_form .form-inline {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.classCheckDetail .baseSet span {
  margin: 20px 0 20px;
}
.classCheckDetail .framePage-body {
  width: 100%;
  overflow-y: auto;
}
.classCheckDetail .el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
